<template>
  <el-container class="container">
    <el-main class="main-wrapper">
      <el-row :gutter="40" style="height: 100%">
        <el-col :span="12" style="height: 100%">
          <div class="main-header">
            <el-input :placeholder="$t('account.accountQueryTips')" :title="query ? '' : $t('account.accountQueryTips')" v-model="query" @keyup.enter.native="searchEvent" clearable>
              <el-button icon="el-icon-search" @click="searchEvent" slot="append"></el-button>
            </el-input>
          </div>
          <div class="main-title">{{ $t('noticePublishRange.UserList') }}</div>
          <div ref="tableWrapper" class="table-wrapper" style="height: calc(100% - 96px)">
            <el-table ref="tableRef" height="calc(100% - 65px)" :data="getaccountlist" style="width: 100%" @select="selectionChange" @select-all="selectAll">
              <el-table-column prop="roleaccount" :label="$t('account.accountId')">
                <template slot-scope="scope">
                  {{ scope.row.roleaccount }}
                </template>
              </el-table-column>
              <el-table-column prop="displayname" :label="$t('account.accountName')">
                <template slot-scope="scope">
                  {{ scope.row.displayname }}
                </template>
              </el-table-column>

              <el-table-column type="selection" width="55"></el-table-column>
              <div slot="empty">
                <el-empty :description="$t('notice.emptyTips')" :image="require('@/assets/empty-icon.svg')" />
              </div>
            </el-table>
            <pagination-box :total="pagesObj.total" :currentPage="pagesObj.page" :pageSize="pagesObj.pagesize" @current-change="getAccountData"></pagination-box>
          </div>
        </el-col>
        <el-col :span="12" style="height: 100%">
          <div class="selected-main-head">
            <div class="select-title">{{ $t('noticePublishRange.selected', { count: selectedData.length }) }}</div>
            <div class="clear-button">
              <el-button icon="el-icon-error" type="text" @click="clearAll">{{ $t('noticePublishRange.clearAll') }}</el-button>
            </div>
          </div>

          <el-table height="calc(100% - 50px)" :data="selectedData" style="width: 100%">
            <el-table-column prop="roleaccount" :label="$t('account.accountId')">
              <template slot-scope="scope">
                {{ scope.row.roleaccount }}
              </template>
            </el-table-column>
            <el-table-column prop="displayname" :label="$t('account.accountName')">
              <template slot-scope="scope">
                {{ scope.row.displayname }}
              </template>
            </el-table-column>
            <el-table-column label="" width="50">
              <template slot-scope="scope">
                <el-button @click="deleteClick(scope.$index)" type="text" icon="el-icon-error"></el-button>
              </template>
            </el-table-column>

            <!-- <div slot="empty">
              <el-empty :description="$t('notice.emptyTips')" :image="require('@/assets/empty-icon.svg')" />
            </div> -->
          </el-table>
        </el-col>
      </el-row>
    </el-main>
    <el-footer class="footer-wrapper" height="70px">
      <el-button @click="closeSelectRange">{{ $t('common.cancel') }}</el-button>
      <el-button type="primary" @click="confirmSelect">{{ $t('common.confirm') }}</el-button>
    </el-footer>
  </el-container>
</template>

<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
